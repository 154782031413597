.blink_section {
  opacity: 0.5 !important;
}

.stage-board {
  /* background-color: #000; */
  background: radial-gradient(circle, rgba(255, 255, 255, 0.174), rgb(7, 4, 4));
}

body {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
supported by Chrome, Edge, Opera and Firefox */
}

.custom-toaster {
  position: fixed;
  top: 50px;
  transform: translateX(-50%);
  left: 50%;
  z-index: 99999;
}

.ReactModal__Overlay {
  background-color: transparent !important;
  z-index: 1000 !important;
}

.ReactModal__Content {
  background-color: #2c2d30 !important;
}

.cashout-toaster1,
.cashout-toaster2 {
  height: 55px;
  border-radius: 55px;
  min-width: 300px;
  background: #123405;
  border: 1px solid #4eaf11;
  display: flex;
  align-items: center;
  transition: all 0.4s;
  padding: 0 0 0 15px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
  opacity: 0;
  margin-bottom: -55px;
  visibility: hidden;
}

.error-toaster1 {
  height: 55px;
  border-radius: 55px;
  min-width: 300px;
  background-color: #b3021b;
  border-color: #b3021b;
  display: flex;
  align-items: center;
  transition: all 0.4s;
  padding: 0 0 0 15px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
  opacity: 0;
  margin-bottom: -55px;
  visibility: hidden;
}

.error-toaster1.show {
  margin: 0;
  opacity: 1;
  visibility: visible;
}

.error-toaster2 {
  height: 55px;
  border-radius: 55px;
  min-width: 300px;
  background-color: #b3021b;
  border-color: #b3021b;
  display: flex;
  align-items: center;
  transition: all 0.4s;
  padding: 0 0 0 15px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
  opacity: 0;
  margin-bottom: -55px;
  visibility: hidden;
}

.error-toaster2.show {
  margin: 0;
  opacity: 1;
  visibility: visible;
}

.cashout-toaster1.show {
  margin: 0;
  opacity: 1;
  visibility: visible;
}

.cashout-toaster2.show {
  opacity: 1;
  visibility: visible;
}

.cashout-toaster2 {
  margin-top: 10px;
}

.cashout-stop {
  color: #d2d2d2;
}

.stop-number {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.got-block {
  width: 120px;
  height: 45px;
  background: #4eaf11;
  border-radius: 45px;
  margin-left: 10px;
  overflow: hidden;
}

.star-left {
  width: 30px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.6;
}

.star-right {
  width: 30px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.6;
}

.got-amout {
  font-size: 13px;
  line-height: 16px;
  color: #fff;
  font-weight: 500;
  z-index: 2;
}

.custom-toaster .material-symbols-outlined {
  color: #72836b;
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
  font-variation-settings: "FILL" 0, "wght" 700, "GRAD" 0, "opsz" 48;
}

.custom-toaster-error {
  background-color: #b3021b;
  border-color: #b3021b;
  border-radius: 10px;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

/* toastr END */

.hide {
  display: none !important;
}

/* .game-play div {
pointer-events: none;
} */
.load-txt {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  z-index: 90;
}

.stop-action {
  pointer-events: none;
  opacity: 0.7;
}

.counter-num {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px;
  font-weight: 700;
  color: #fff;
  z-index: 3;
}

.counter-num span {
  font-size: 80px;
  font-weight: 700;
  margin-left: 5px;
}

/* .list-body {
max-height: 500px !important;
} */
.text-danger {
  color: #f7001f !important;
}

@media (max-width: 768px) {
  #auto_increment_number_div .flew_away_section {
    font-size: 18px;
  }

  #auto_increment_number_div #auto_increment_number {
    font-size: 65px;
    line-height: 40px;
  }

  .center-loading svg {
    width: 100px;
    height: 50px;
  }

  .game-play {
    height: auto;
  }

  #extra_bet_section {
    margin-top: 10px;
  }

  footer {
    display: none;
  }
}

.bet-border-red {
  border-color: #f7001f !important;
}

.bet-border-yellow {
  border-color: #e69308 !important;
}
